export const ru = {
  //Menu
  Home: "Главная",
  Contact: "Контакты",
  "About Me": "Обо Мне",

  //Header
  Hello: "Привет",
  "My name is Nikita Atroshenko": "Меня зовут Никита Атрошенко",
  "I'm": "Я",
  Developer: "Разработчик",


  //About Me
  about_me_1: "Я молодой и полный энтузиазма разработчик.",
  about_me_2:
    "Я занимаюсь разработкой Full-Stack приложений. Моя текущая практика на Back-End - это Node JS. Мои любимые:",
  about_me_3: "Для Front-End я предпочитаю следующие фреймворки:",
  about_me_4: "В последнее время я также работаю с такими технологиями, как",
  about_me_5:
    "Это меньшее из того, что я умею. Поэтому, пожалуйста, не стесняйтесь обращаться ко мне по ссылкам ниже.",
  //footer
  CV: "Резюме",
};
