export const en = {
  //Menu
  Home: "Home",
  "About Me": "About Me",
  Contact: "Contact",

  //Header
  Hello: "Hello",
  "My name is Nikita Atroshenko": "My name is Nikita Atroshenko",
  "I'm": "I'm",
  Developer: "Developer",

  //About Me
  about_me_1: "I am young and enthusiastic developer.",
  about_me_2:
    "My belongingness is Full-Stack development. My current practice at the Back-End is Node JS. My favourites are:",
  about_me_3: "For the Front-End:",
  about_me_4: "Recently, I have also been working with such technologies as",
  about_me_5:
    "This is the least of what I can do. Therefore, please do not hesitate to contact me via links below.",
  //footer
  CV: "CV",
};
